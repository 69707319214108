import actionType from "src/actions/SmartAccessManagementSolutionTaggingActionTypes";
import { ISmartAccessManagementSolutionTagging } from "src/types/Smart/AccessManagemnetSolutionTagging";

const initialState: ISmartAccessManagementSolutionTagging = {
  dNumberEditorsList: {
    loading: false,
    data: [],
    error: false,
    success: false,
  },
  AccessNewEditorAdded: {
    loading: false,
    error: false,
    success: false,
  },
};

const SmartAccessManagementSolutionTaggingReducer = (
  state = initialState,
  action: any
): ISmartAccessManagementSolutionTagging => {  switch (action.type) {
    
    case actionType.GET_ACCESS_DNUMBER_MAPPING:
      return {
        ...state,
        dNumberEditorsList: {
          ...state.dNumberEditorsList,
          loading: true,
          success: false,
          error: false,
        },
      };

    case actionType.GET_ACCESS_DNUMBER_MAPPING_SUCCESS:
      return {
        ...state,
        dNumberEditorsList: {
          loading: false,
          data: action.payload,
          success: true,
          error: false,
        },
      };

    case actionType.GET_ACCESS_DNUMBER_MAPPING_ERROR:
      return {
        ...state,
        dNumberEditorsList: {
          loading: false,
          data: [],
          success: false,
          error: true,
        },
      };

    case actionType.CREATE_ACCESS_DNUMBER_MAPPING:
      return {
        ...state,
        AccessNewEditorAdded: {
          ...state.AccessNewEditorAdded,
          loading: true,
          success: false,
          error: false,
        },
      };

    case actionType.CREATE_ACCESS_DNUMBER_MAPPING_SUCCESS:
     /*
      return {
        dNumberEditorsList: {
          ...state.dNumberEditorsList,
          data: state.dNumberEditorsList.data.map((item) => {
           console.log("data is processing");
            if (item.dNumber === updatedData.dNumber) {
              console.log("data is matched");
              return { ...item, editors: updatedData.editors };
            }
            return item; 
          }),
        },
        AccessNewEditorAdded: {      
          loading: false,
          success: true,
          error: false,
        },
        
      };*/
      
      return {
        ...state,
        AccessNewEditorAdded: {
          loading: false,
          success: true,
          error: false,
        },
      };
       
    case actionType.CREATE_ACCESS_DNUMBER_MAPPING_ERROR:
      return {
        ...state,
        AccessNewEditorAdded: {
          loading: false,
          success: false,
          error: true,
        },
      };
    case actionType.UPDATE_CREATE_ACCESS_MANAGEMENT_SOLUTION_TAGGING:
     
      const updatedData = action.payload;
     
      return {
        ...state, 
        dNumberEditorsList: {
          ...state.dNumberEditorsList,
          data: state.dNumberEditorsList.data.map((item) => {
          
            if (item.dNumber === updatedData.dNumber) {
              return { ...item, editors: updatedData.editors };
            }
            return item; 
          }),
        },
      };
    default: {
     
      return state;
    }
  }
};

export default SmartAccessManagementSolutionTaggingReducer;
