import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import './CustomTooltip.scss';
import { Tooltip } from 'primereact/tooltip';
import { TooltipPositionType } from 'primereact/tooltip/tooltipoptions';

interface IProps {
    target: string,
    text: string,
    position?: TooltipPositionType
}
const CustomTooltip = ({ target, text, position }: IProps) => {
    return (<Tooltip target={target} mouseTrack autoHide={false} hideDelay={100} position={position}  >
        <div className='tool-tip-text-container'>
            {text}
        </div>
    </Tooltip >)
}

export default CustomTooltip;