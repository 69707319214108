import actionType from "src/actions/SmartSolutionTaggingActionTypes";
import { ISolutionTagging } from "src/types/Smart/SmartSolutionTagging";

const initialState: ISolutionTagging = {
  dNumberList: {
    loading: false,
    error: false,
    success: false,
    data: [
    ],
  },
    
  
  companyList: {
    loading: false,
    data:[],
    error: false,
    success: false,
  },
  erorrData:{
    loading:false,
    data:[],
    error:false,
    success:false
  },
  postErrorData: {
    loading: false,
    error: false,
    success: false,
  },
};
const SmartSolutionTagging = (
  state = initialState,
  action: any
): ISolutionTagging => {
  switch (action.type) {
  
    case actionType.GET_DNUMBER:
     
      return {
        ...state,
        dNumberList: {
          ...state.dNumberList,
          loading: true,
          success: false,
          error: false,
        },
      };

    case actionType.GET_DNUMBER_SUCCESS:
      return {
        ...state,
        dNumberList: {
          loading: false,
          data: action.payload,
          success: true,
          error: false,
        },
      };

    case actionType.GET_DNUMBER_ERROR:
      return {
        ...state,
        dNumberList: {
          loading: false,
          data:[],
          success: false,
          error: true,
        },
      };
    case actionType.GET_COMPANY:
      return {
        ...state,
        companyList: {
          ...state.companyList,
          loading: true,
          success: false,
          error: false,
        },
      };

    case actionType.GET_COMPANY_SUCCESS:
      return {
        ...state,
        companyList: {
          loading: false,
          data: action.payload,
          success: true,
          error: false,
        },
      };

    case actionType.GET_COMPANY_ERROR:
      return {
        ...state,
        companyList: {
          loading: false,
          data: [],
          success: false,
          error: true,
        },
      };
     //error data
     case actionType.GET_ERROR_DATA:
      return {
        ...state,
        erorrData: {
          ...state.erorrData,
          loading: true,
          success: false,
          error: false,
        },
      };

    case actionType.GET_ERROR_DATA_SUCCESS:
      return {
        ...state,
        erorrData: {
          loading: false,
          data: action.payload,
          success: true,
          error: false,
        },
      };

    case actionType.GET_ERROR_DATA_ERROR:
      return {
        ...state,
        erorrData: {
          loading: false,
          data: [],
          success: false,
          error: true,
        },
      };
    
      case actionType.POST_ERROR_DATA:
      return {
        ...state,
        postErrorData: {
          ...state.postErrorData,
          loading: true,
          success: false,
          error: false,
        },
      };

    case actionType.POST_ERROR_DATA_SUCCESS:
     
      return {
        ...state,
        postErrorData: {
          loading: false,
          success: true,
          error: false,
        },
      };
       
    case actionType.POST_ERROR_DATA_ERROR:
      return {
        ...state,
        postErrorData: {
          loading: false,
          success: false,
          error: true,
        },
      }; 
      case actionType.CLEAR_ERROR_DATA:
        return{
          ...initialState,
        };
      default: {
      return state;
    }
  }
};

export default SmartSolutionTagging;
