import axios from "axios";
import { config } from "./config";

interface IPostApi {
  url: string,
  parameter: any,
  payload: any,
}

export class BaseApi {
  get(
    url: string,
    parameter: any,
    requestAction: any,
    requestSuccessAction: any,
    errorAction: any
  ) {
    const apiUrl = config.baseUrl + url;
    return (dispatch: any) => {
      dispatch(requestAction(url));
      axios
        .get(apiUrl, { params: parameter })
        .then((apiData: any) => {
          if (apiData.status === 200) {
            dispatch(requestSuccessAction(apiData.data, parameter, url));
          }
        })
        .catch((apiError) => {
          dispatch(errorAction(apiError, url));
        });
    };
  }

  post(
    url: string,
    parameter: any,
    payload: any,
    requestAction: any,
    requestSuccessAction: any,
    errorAction: any
  ) {
    const apiUrl = config.baseUrl + url;
    return (dispatch: any) => {
      dispatch(requestAction(url));
      axios
        .post(apiUrl, payload, { params: parameter })
        .then((apiData: any) => {
          if (apiData.status === 200) {
            dispatch(requestSuccessAction(apiData.data, parameter, url));
          }
        })
        .catch((apiError) => {
          dispatch(errorAction(apiError, url));
        });
    };
  }

  put(
    url: string,
    parameter: any,
    payload: any,
    requestAction: any,
    requestSuccessAction: any,
    errorAction: any
  ) {
    const apiUrl = config.baseUrl + url;
    return (dispatch: any) => {
      dispatch(requestAction(url));
      axios
        .put(apiUrl, payload, { params: parameter })
        .then((apiData: any) => {
          if (apiData.status === 200) {
            dispatch(requestSuccessAction(apiData.data, url));
          }
        })
        .catch((apiError) => {
          dispatch(errorAction(apiError, url));
        });
    };
  }

  delete(
    url: string,
    parameter: any,
    payload: any,
    requestAction: any,
    requestSuccessAction: any,
    errorAction: any
  ) {
    const apiUrl = config.baseUrl + url;
    return (dispatch: any) => {
      dispatch(requestAction(url));
      axios
        .delete(apiUrl, { data: payload })
        .then((apiData: any) => {
          if (apiData.status === 200) {
            dispatch(requestSuccessAction(apiData.data, url));
          }
        })
        .catch((apiError) => {
          dispatch(errorAction(apiError, url));
        });
    };
  }

  getFromJson(
    url: string,
    parameter: any,
    requestAction: any,
    requestSuccessAction: any,
    errorAction: any
  ) {
    const apiUrl = config.jsonUrl + url;
    return async (dispatch: any) => {
      dispatch(requestAction(url));
      axios
        .get(apiUrl, {
          params: { ...parameter, noBearer: true },
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        })
        .then((apiData: any) => {
          if (apiData.status === 200) {
            dispatch(requestSuccessAction(apiData.data, parameter, url));
          }
        })
        .catch((apiError) => {
          dispatch(errorAction(apiError, url));
        });
      // let data = await fetch(apiUrl);
      // data.json().then((apiData: any) => {
      //   if (apiData.status === 200) {
      //     dispatch(requestSuccessAction(apiData.data, parameter, url))
      //   }
      // })
      //   .catch((apiError) => {
      //     dispatch(errorAction(apiError, url))
      //   })
    };
  }



  clubPostApi(
    apis: IPostApi[],
    requestAction: any,
    requestSuccessAction: any,
    errorAction: any
  ) {
    const apiUrl = config.baseUrl;
    return async (dispatch: any) => {
      dispatch(requestAction());
      let allPromise: any = [];
      apis.forEach((item: IPostApi) => {
        allPromise.push(
          axios
            .post(apiUrl + item.url, item.payload, { params: item.parameter }))
      })
      Promise.all(allPromise)
        .then((apiData: any) => {
          let data: any = {};
          apiData.forEach((item: any) => {
            data[item.config.params.name] = item.data.data;
          })

          dispatch(requestSuccessAction(data));
        })
        .catch((apiError) => {
          dispatch(errorAction(apiError));
        });
    }
  }
}