const actionType = {
  GET_ACCESS_DNUMBER_MAPPING: "GET_ACCESS_DNUMBER_MAPPING",
  GET_ACCESS_DNUMBER_MAPPING_SUCCESS: "GET_ACCESS_DNUMBER_MAPPING_SUCCESS",
  GET_ACCESS_DNUMBER_MAPPING_ERROR: "GET_ACCESS_DNUMBER_MAPPING_ERROR",

  CREATE_ACCESS_DNUMBER_MAPPING: "CREATE_ACCESS_DNUMBER_MAPPING",
  CREATE_ACCESS_DNUMBER_MAPPING_SUCCESS:
    "CREATE_ACCESS_DNUMBER_MAPPING_SUCCESS",
  CREATE_ACCESS_DNUMBER_MAPPING_ERROR: "CREATE_ACCESS_DNUMBER_MAPPING_ERROR",
  UPDATE_CREATE_ACCESS_MANAGEMENT_SOLUTION_TAGGING: "UPDATE_CREATE_ACCESS_MANAGEMENT_SOLUTION_TAGGING",
};
export default actionType;
