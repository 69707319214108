const actionType = {
  GET_DNUMBER: "GET_DNUMBER",
  GET_DNUMBER_SUCCESS: "GET_DNUMBER_SUCCESS",
  GET_DNUMBER_ERROR: "GET_DNUMBER_FAILURE",

  GET_COMPANY: "GET_COMPANY",
  GET_COMPANY_SUCCESS: "GET_COMPANY_SUCCESS",
  GET_COMPANY_ERROR: "GET_COMPANY_FAILURE",

  GET_ERROR_DATA:"GET_ERROR_DATA",
  GET_ERROR_DATA_SUCCESS:"GET_ERROR_DATA_SUCCESS",
  GET_ERROR_DATA_ERROR:"GET_ERROR_DATA_ERROR",

  POST_ERROR_DATA:"POST_ERROR_DATA",
  POST_ERROR_DATA_SUCCESS:"POST_ERROR_DATA_SUCCESS",
  POST_ERROR_DATA_ERROR:"POST_ERROR_DATA_ERROR",

  GET_DOWNLOAD_DATA: 'GET_DOWNLOAD_DATA',
  GET_DOWNLOAD_DATA_SUCCESS: 'GET_DOWNLOAD_DATA_SUCCESS',
  GET_DOWNLOAD_DATA_ERROR: 'GET_DOWNLOAD_DATA_ERROR',

  CLEAR_ERROR_DATA:'CLEAR_ERROR_DATA',
  CLEAR_ERROR_DATA_SUCCESS: 'CLEAR_ERROR_DATA_SUCCESS',
  CLEAR_ERROR_DATA_ERROR: 'CLEAR_ERROR_DATA_ERROR',
};
export default actionType;
