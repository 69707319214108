import { combineReducers } from "redux";

import loginReducer from "./login.reducer";
import reportRequestReducer from "./reportRequest.reducer";
import viewRequestReducer from "./viewPendingRequest.reducer";
import viewApproveRequestReducer from "./viewApproveRequest.reducer";
import notificationReducer from "./notification.reducer";
import filterReducer from "./filter.reducer";
import BreadCrumbReducer from "./BreadCrumb.reducer";
import reportSearchReducer from "./reportList.reducer";
import DataRefreshingReducer from "./DataRefreshing.reducer";
import piDisclaimerReducer from "./piDisclaimer.reducer";
import userRoleReducer from "./UserRole.reducer";
import manageUserReducer from "./ManageUser.reducer";
import searchKeyReducers from "./SearchKey.reducer";
import manageKpiReducer from "./ManageKpi.reducer";
import manageReportReducer from "./ManageReport.reducer";
import manageFilterReducer from "./ManageFilter.reducer";
import tableauFilterReducer from "./TableauReport.reducer";
import piDataDeleteReducer from "./PiDataDelete.reducer";
import ManageDeReducer from "./ManageDe.reducer";
import TableauRequestReducer from "./TableauRequest.Reducer";
import manageScoreGroupReducer from "./ManageScoreGroup.reducer";
import biHomePageReducer from "./BiHomePage.reducer";
import manageTableauSuperUserReducer from "./ManageTableuSuperUser.reducer";
import candidateUniquenesReducer from "./CandidateUniqueness.reducer";
import manageSolutionMappingReducer from "./ManageSolutionMapping.reducer";
import manageExtractReducer from "./ManageExtract.reducer";
import manageCategoryReducer from "./ManageCategory.reducer";
import manageCaReportReducer from "./ManageCaReport.reducer";
import caReportReducer from "./CaReport.reducer";
import customDashboardReportReducer from "./CustomDashboardReport.reducer";
import manageFactReducer from "./ManageFact.reducer";
import manageQuizReducer from "./ManageQuiz.reducer";

import manageNotificationReducer from "./ManageNotifications.reducer";
import GroupEmailReducer from "./GroupEmail.reducer";
import manageUserGroupReducer from "./ManageUserGroup.reducer";
import manageSmartDeReducer from "./ManageSMARTDE.reducer";
import manageRTBRequestReducer from "./ManageRTBRequest.reducer";
import ManageGlueJobReducer from "./ManageGlueJob.reducer";
import jiraReducer from "./Jira.reducer";
import LeaderboardReducer from "./Leaderboard.reducer";
import TimesheetSquadReducer from "./TimesheetSquad.reducer";
import TimesheetProjectReducer from "./TimesheetProject.reducer";
import TimesheetUsersReducer from "./TimesheetUsers.reducer";
import TimesheetMyEntryReducer from "./TimesheetMyEntry.reducer";
import TimesheetTeamEntryReducer from "./TimesheetTeamEntry.reducer";
import TimesheetInitiativeReducer from "./TimesheetInitiative.reducer";
import secureExtractReducer from "./SecureExtract.reducer";
import smartUsageReducer from "./SmartUsage.reducer";
import smartAlertsNotificationsReducer from "./SmartAlertNotification.reducer";
import SmartAccessManagementSolutionTaggingReducer from "./SmartAccessManagementSolutionTagging.reducer";
import SmartSolutionTagging from "./SmartSolutionTagging.reducer";
import { IStoreState } from "src/types/StoreState";

const rootReducer = combineReducers({
  login: loginReducer,
  reportRequest: reportRequestReducer,
  viewRequest: viewRequestReducer,
  viewApproveRequest: viewApproveRequestReducer,
  notification: notificationReducer,
  filter: filterReducer,
  breadCrumb: BreadCrumbReducer,
  dataRefresh: DataRefreshingReducer,
  piDisclaimer: piDisclaimerReducer,
  reportSearch: reportSearchReducer,
  userRole: userRoleReducer,
  manageUser: manageUserReducer,
  searchKey: searchKeyReducers,
  manageKpi: manageKpiReducer,
  manageReport: manageReportReducer,
  manageFilter: manageFilterReducer,
  tableauReport: tableauFilterReducer,
  piDataDelete: piDataDeleteReducer,
  manageDe: ManageDeReducer,
  tableauRequest: TableauRequestReducer,
  manageScoreGroup: manageScoreGroupReducer,
  biHomePage: biHomePageReducer,
  manageTableauSuperUser: manageTableauSuperUserReducer,
  manageSolutionMapping: manageSolutionMappingReducer,
  candidateUniquenes: candidateUniquenesReducer,
  manageExtract: manageExtractReducer,
  manageCategory: manageCategoryReducer,
  manageCaReport: manageCaReportReducer,
  caReport: caReportReducer,
  customDashboardReport: customDashboardReportReducer,
  manageFact: manageFactReducer,
  manageQuiz: manageQuizReducer,
  manageNotification: manageNotificationReducer,
  groupEmail: GroupEmailReducer,
  manageUserGroup: manageUserGroupReducer,
  manageSmartDe: manageSmartDeReducer,
  manageRTBRequest: manageRTBRequestReducer,
  manageGlueJob: ManageGlueJobReducer,
  jira: jiraReducer,
  leaderboard: LeaderboardReducer,
  timeSheetSquads: TimesheetSquadReducer,
  timeSheetProjects: TimesheetProjectReducer,
  timeSheetInitiatives: TimesheetInitiativeReducer,
  timesheetUsers: TimesheetUsersReducer,
  timesheetMyEntry: TimesheetMyEntryReducer,
  timesheetTeamEntry: TimesheetTeamEntryReducer,
  secureExtract: secureExtractReducer,
  smartUsage: smartUsageReducer,
  smartAlertsNotifications: smartAlertsNotificationsReducer,
  smartAccessManagementSolutionTagging:
    SmartAccessManagementSolutionTaggingReducer,
  smartSolutionTagging: SmartSolutionTagging,
});

export default rootReducer;
